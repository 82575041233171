<template>
  <div class="swatch" :style="{ backgroundColor: color }">
    <p class="hex" :style="{ color: contrastColor }">
      {{ color }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'PortfolioColorSwatch',
  props: {
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    contrastColor() {
      let hex = this.color;

      if (hex.indexOf('#') === 0) hex = hex.slice(1);

      if (hex.length === 3)
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];

      if (hex.length !== 6) throw new Error('Invalid HEX color.');

      let r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);

      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
    },
  },
};
</script>

<style lang="scss" scoped>
.swatch {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 24px;
  border-radius: 12px;
  box-shadow: map-get($elevation, 2);
  box-sizing: border-box;
  overflow: hidden;

  @media all and ($mobile) {
    padding: 16px 8px;
  }

  .hex {
    font-size: 1.5rem;
    font-weight: 700;
    opacity: 0.3;

    @media all and ($mobile) {
      font-size: 1rem;
    }
  }
}
</style>
