import { render, staticRenderFns } from "./DesignerVintageClothing.vue?vue&type=template&id=174f1ce4&scoped=true&"
import script from "./DesignerVintageClothing.vue?vue&type=script&lang=js&"
export * from "./DesignerVintageClothing.vue?vue&type=script&lang=js&"
import style0 from "./layout/projects.scss?vue&type=style&index=0&id=174f1ce4&lang=scss&scoped=true&"
import style1 from "./DesignerVintageClothing.vue?vue&type=style&index=1&id=174f1ce4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "174f1ce4",
  null
  
)

/* custom blocks */
import block0 from "@/locales/pages/designer-vintage-clothing.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CBibliotheken%5CDocuments%5CDennis-Adamczyk%5CWebsite%20-%20dennis-adamczyk.de%5Cv3%5Cdennis-adamczyk%5Csrc%5Cpages%5CPortfolio%5CDesignerVintageClothing.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports