<i18n src="@/locales/pages/designer-vintage-clothing.json"></i18n>

<template>
  <div>
    <PortfolioIntro id="intro">
      <template #title>
        {{ $t('portfolio.designerVintageClothing.intro.title') }}
      </template>
      <template #subtitle>
        {{ $t('portfolio.designerVintageClothing.intro.subtitle') }}
      </template>
      <template #description>
        {{ $t('portfolio.designerVintageClothing.intro.description') }}
      </template>

      <template #services>
        {{ $t('portfolio.designerVintageClothing.intro.details.services') }}
      </template>
      <template #tools>
        {{ $t('portfolio.designerVintageClothing.intro.details.tools') }}
      </template>
      <template #year>
        {{ $t('portfolio.designerVintageClothing.intro.details.year') }}
      </template>
      <template #preview>
        <a target="_blank" href="https://designer-vintage-clothing.de"
          >designer-vintage-clothing.de</a
        >
      </template>
    </PortfolioIntro>
    <section id="idea">
      <div class="outer">
        <div class="content">
          <div class="inner">
            <picture class="mockup">
              <source
                media="(max-width: 599px)"
                srcset="@/assets/portfolio/designer-vintage-clothing/mobileMockup.webp"
                type="image/webp"
              />
              <source
                media="(max-width: 599px)"
                srcset="@/assets/portfolio/designer-vintage-clothing/mobileMockup.png"
                type="image/png"
              />
              <source
                srcset="@/assets/portfolio/designer-vintage-clothing/introMockup.webp"
                type="image/webp"
              />
              <source
                srcset="@/assets/portfolio/designer-vintage-clothing/introMockup.png"
                type="image/png"
              />
              <img
                src="@/assets/portfolio/designer-vintage-clothing/introMockup.png"
                alt=""
              />
            </picture>
            <div class="text">
              <p
                class="background-text rellax"
                data-rellax-speed="-1"
                data-rellax-percentage="0.5"
              >
                {{ $t('portfolio.designerVintageClothing.idea.title.short') }}
              </p>
              <h2 class="title">
                {{ $t('portfolio.designerVintageClothing.idea.title.long') }}
              </h2>
              <article
                v-for="(block, i) in $t(
                  'portfolio.designerVintageClothing.idea.blocks'
                )"
                :key="i"
              >
                <h3>
                  {{ block.heading }}
                </h3>
                <p>
                  {{ block.text }}
                </p>
              </article>
            </div>
          </div>
          <div class="diagrams">
            <img
              src="@/assets/portfolio/designer-vintage-clothing/diagram1.svg"
              alt="Diagram 1"
            />
            <img
              src="@/assets/portfolio/designer-vintage-clothing/diagram2.svg"
              alt="Diagram 2"
            />
          </div>
        </div>
      </div>
    </section>
    <section id="concept">
      <div class="content">
        <p
          class="background-text rellax"
          data-rellax-speed="-1"
          data-rellax-percentage="0.5"
        >
          {{ $t('portfolio.designerVintageClothing.concept.title.short') }}
        </p>
        <h2 class="title">
          {{ $t('portfolio.designerVintageClothing.concept.title.long') }}
        </h2>
        <article class="procedure">
          <h3>
            {{
              $t('portfolio.designerVintageClothing.concept.procedure.heading')
            }}
          </h3>
          <div class="chart">
            <div class="start">
              <p>
                {{
                  $t(
                    'portfolio.designerVintageClothing.concept.procedure.chart.start'
                  )
                }}
              </p>
            </div>
            <template
              v-for="(step, i) in $t(
                'portfolio.designerVintageClothing.concept.procedure.chart.steps'
              )"
            >
              <div class="arrow" :key="`a${i}`"></div>
              <div class="step" :key="`s${i}`">
                <p class="number">{{ i > 9 ? i : '0' + i }}</p>
                <div class="text">
                  <p class="heading">{{ step.title }}</p>
                  <div class="details">
                    {{ step.details }}
                  </div>
                </div>
              </div>
            </template>
          </div>
        </article>
        <article class="goals">
          <h3>
            {{ $t('portfolio.designerVintageClothing.concept.goals.heading') }}
          </h3>
          <div class="boxes">
            <div
              class="box"
              v-for="(box, i) in $t(
                'portfolio.designerVintageClothing.concept.goals.boxes'
              )"
              :key="i"
            >
              <div class="header">
                <p class="title">
                  {{ box.title }}
                </p>
                <div class="icon">
                  <i v-if="i == 1" class="icon-leaf"></i>
                  <i v-if="i == 2" class="icon-money"></i>
                  <i v-if="i == 3" class="icon-eyedropper"></i>
                  <i v-if="i == 4" class="icon-shipping"></i>
                  <i v-if="i == 5" class="icon-paypal"></i>
                </div>
              </div>
              <p class="details">
                {{ box.details }}
              </p>
            </div>
          </div>
          <div class="conclusion">
            <div class="arrow"></div>
            <p
              v-html="
                $t('portfolio.designerVintageClothing.concept.goals.conclusion')
              "
            ></p>
          </div>
        </article>
      </div>
    </section>
    <section id="target">
      <div class="content">
        <p
          class="background-text rellax"
          data-rellax-speed="-1"
          data-rellax-percentage="0.5"
        >
          {{ $t('portfolio.designerVintageClothing.target.title.short') }}
        </p>
        <h2 class="title">
          {{ $t('portfolio.designerVintageClothing.target.title.long') }}
        </h2>
        <div class="groups">
          <article
            class="group"
            v-for="(group, i) in $t(
              'portfolio.designerVintageClothing.target.groups'
            )"
            :key="i"
          >
            <h3>
              {{ group.heading }}
            </h3>
            <p class="description" v-html="group.description"></p>
          </article>
        </div>
      </div>
    </section>
    <section id="design">
      <div class="content">
        <p
          class="background-text rellax"
          data-rellax-speed="-1"
          data-rellax-percentage="0.5"
        >
          {{ $t('portfolio.designerVintageClothing.design.title.short') }}
        </p>
        <h2 class="title">
          {{ $t('portfolio.designerVintageClothing.design.title.long') }}
        </h2>
        <article class="logo">
          <h3>
            {{ $t('portfolio.designerVintageClothing.design.logo.heading') }}
          </h3>
          <div class="iteration">
            <div class="number">01</div>
            <div class="inner">
              <img
                src="@/assets/portfolio/designer-vintage-clothing/idea01.svg"
                alt="Idea 01"
              />
              <div class="arrow"></div>
              <img
                src="@/assets/portfolio/designer-vintage-clothing/logo01.svg"
                alt="Logo 01"
              />
            </div>
          </div>
          <div class="iteration">
            <div class="number">02</div>
            <div class="inner">
              <img
                src="@/assets/portfolio/designer-vintage-clothing/idea02.svg"
                alt="Idea 02"
              />
              <div class="arrow"></div>
              <img
                src="@/assets/portfolio/designer-vintage-clothing/logo02.svg"
                alt="Logo 02"
              />
            </div>
          </div>
          <div class="final">
            <div class="number">Final</div>
            <div class="inner">
              <img
                src="@/assets/portfolio/designer-vintage-clothing/finalLogo.svg"
                alt="Final Logo"
              />
              <p
                class="description"
                v-html="
                  $t('portfolio.designerVintageClothing.design.logo.final')
                "
              ></p>
            </div>
          </div>
        </article>
        <article class="colors">
          <h3>
            {{ $t('portfolio.designerVintageClothing.design.colors.heading') }}
          </h3>
          <div class="inner">
            <div class="swatches">
              <PortfolioColorSwatch color="#293241"></PortfolioColorSwatch>
              <PortfolioColorSwatch color="#333333"></PortfolioColorSwatch>
              <PortfolioColorSwatch color="#FAFAFA"></PortfolioColorSwatch>
              <PortfolioColorSwatch color="#4E4E4E"></PortfolioColorSwatch>
              <PortfolioColorSwatch color="#2980B9"></PortfolioColorSwatch>
              <PortfolioColorSwatch color="#000000"></PortfolioColorSwatch>
            </div>
            <div class="text">
              <p
                v-for="(detail, i) in $t(
                  'portfolio.designerVintageClothing.design.colors.details'
                )"
                :key="i"
              >
                {{ detail }}
              </p>
            </div>
          </div>
        </article>
        <article class="typography">
          <h3>
            {{
              $t('portfolio.designerVintageClothing.design.typography.heading')
            }}
          </h3>
          <div class="inner">
            <p class="description">
              {{
                $t(
                  'portfolio.designerVintageClothing.design.typography.description'
                )
              }}
            </p>
            <div class="fonts">
              <PortfolioFont family="Amatic SC" />
              <PortfolioFont family="Andika" />
              <PortfolioFont family="Lato" />
            </div>
          </div>
        </article>
      </div>
    </section>
    <section id="result">
      <a
        target="_blank"
        href="https://designer-vintage-clothing.de"
        class="visitSite"
      >
        {{ $t('portfolio.designerVintageClothing.result.visitSite') }}
        <i class="icon-open-in-new"></i>
      </a>
      <div class="content">
        <p
          class="background-text rellax"
          data-rellax-speed="-1"
          data-rellax-percentage="0.5"
        >
          {{ $t('portfolio.designerVintageClothing.result.title.short') }}
        </p>
        <article class="intro">
          <h2 class="title">
            {{ $t('portfolio.designerVintageClothing.result.title.long') }}
          </h2>
          <i18n
            path="portfolio.designerVintageClothing.result.description.text"
            class="description"
            tag="p"
          >
            <template #wordpress>
              <a href="https://wordpress.org/" target="_blank">
                {{
                  $t(
                    'portfolio.designerVintageClothing.result.description.wordpress'
                  )
                }}
              </a>
            </template>
            <template #woocommerce>
              <a
                href="https://de.wordpress.org/plugins/woocommerce/"
                target="_blank"
                >{{
                  $t(
                    'portfolio.designerVintageClothing.result.description.woocommerce'
                  )
                }}</a
              >
            </template>
            <template #astra>
              <a
                href="https://de.wordpress.org/themes/astra/"
                target="_blank"
                >{{
                  $t(
                    'portfolio.designerVintageClothing.result.description.astra'
                  )
                }}</a
              >
            </template>
            <template #elementor>
              <a
                href="https://de.wordpress.org/plugins/elementor/"
                target="_blank"
                >{{
                  $t(
                    'portfolio.designerVintageClothing.result.description.elementor'
                  )
                }}</a
              >
            </template>
          </i18n>
        </article>
        <div class="gallery">
          <article class="home">
            <h3>
              {{ $t('portfolio.designerVintageClothing.result.home.heading') }}
            </h3>
            <p class="details">
              {{ $t('portfolio.designerVintageClothing.result.home.details') }}
            </p>
          </article>
          <picture class="scHome">
            <source
              srcset="@/assets/portfolio/designer-vintage-clothing/sc_home.webp"
              type="image/webp"
            />
            <source
              srcset="@/assets/portfolio/designer-vintage-clothing/sc_home.jpg"
              type="image/jpg"
            />
            <img
              src="@/assets/portfolio/designer-vintage-clothing/sc_home.jpg"
              alt="Screenshot"
            />
          </picture>
          <picture class="scAbout">
            <source
              srcset="@/assets/portfolio/designer-vintage-clothing/sc_about.webp"
              type="image/webp"
            />
            <source
              srcset="@/assets/portfolio/designer-vintage-clothing/sc_about.jpg"
              type="image/jpg"
            />
            <img
              src="@/assets/portfolio/designer-vintage-clothing/sc_about.jpg"
              alt="Screenshot"
            />
          </picture>
          <picture class="scPackaging1">
            <source
              srcset="@/assets/portfolio/designer-vintage-clothing/sc_packaging1.webp"
              type="image/webp"
            />
            <source
              srcset="@/assets/portfolio/designer-vintage-clothing/sc_packaging1.jpg"
              type="image/jpg"
            />
            <img
              src="@/assets/portfolio/designer-vintage-clothing/sc_packaging1.jpg"
              alt="Screenshot"
            />
          </picture>
          <picture class="scPackaging2">
            <source
              srcset="@/assets/portfolio/designer-vintage-clothing/sc_packaging2.webp"
              type="image/webp"
            />
            <source
              srcset="@/assets/portfolio/designer-vintage-clothing/sc_packaging2.jpg"
              type="image/jpg"
            />
            <img
              src="@/assets/portfolio/designer-vintage-clothing/sc_packaging2.jpg"
              alt="Screenshot"
            />
          </picture>
          <picture class="scHome2">
            <source
              srcset="@/assets/portfolio/designer-vintage-clothing/sc_home2.webp"
              type="image/webp"
            />
            <source
              srcset="@/assets/portfolio/designer-vintage-clothing/sc_home2.jpg"
              type="image/jpg"
            />
            <img
              src="@/assets/portfolio/designer-vintage-clothing/sc_home2.jpg"
              alt="Screenshot"
            />
          </picture>
          <picture class="scShop">
            <source
              srcset="@/assets/portfolio/designer-vintage-clothing/sc_shop.webp"
              type="image/webp"
            />
            <source
              srcset="@/assets/portfolio/designer-vintage-clothing/sc_shop.jpg"
              type="image/jpg"
            />
            <img
              src="@/assets/portfolio/designer-vintage-clothing/sc_shop.jpg"
              alt="Screenshot"
            />
          </picture>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PortfolioIntro from './layout/PortfolioIntro';
import PortfolioColorSwatch from './layout/PortfolioColorSwatch';
import PortfolioFont from './layout/PortfolioFont';

import Rellax from 'rellax';

export default {
  name: 'DesignerVintageClothing',
  components: {
    PortfolioIntro,
    PortfolioColorSwatch,
    PortfolioFont,
  },
  metaInfo() {
    return {
      title: 'Designer Vintage Clothing ◃ Portfolio ◂ Dennis Adamczyk',
      meta: [
        {
          name: 'description',
          content:
            'Designer Vintage Clothing – Portfolio – Dennis Adamczyk • Web Developer & UI/UX Designer – Shop for cheap second-hand designer clothing',
        },
        {
          name: 'keywords',
          content:
            'dennis adamczyk, Designer Vintage Clothing, dvc, shop, portfolio, projekt',
        },
      ],
    };
  },
  mounted() {
    new Rellax('.rellax', {
      breakpoints: [600, 768, 1201],
    });
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      let visitSiteBtn = document.querySelector('#result .visitSite');
      let resultSection = document.getElementById('result');
      let footer = document.querySelector('#app > footer');

      if (
        window.scrollY >
        document.documentElement.scrollHeight - resultSection.offsetHeight
      ) {
        if (!visitSiteBtn.classList.contains('show'))
          visitSiteBtn.classList.add('show');

        let updateBottom = () => {
          if (
            window.scrollY >=
            document.documentElement.scrollHeight -
              window.innerHeight -
              footer.offsetHeight
          ) {
            let newBottom =
              window.scrollY +
              window.innerHeight -
              (document.documentElement.scrollHeight - footer.offsetHeight);
            visitSiteBtn.style.setProperty(
              'transition',
              'background 120ms ease, bottom 0ms ease, opacity 120ms ease'
            );
            visitSiteBtn.style.setProperty(
              'bottom',
              (newBottom || 0) + 24 + 'px'
            );
          } else {
            visitSiteBtn.removeAttribute('style');
          }
        };
        updateBottom();
        setTimeout(updateBottom, 20);
      } else {
        if (visitSiteBtn.classList.contains('show'))
          visitSiteBtn.classList.remove('show');
      }
    },
  },
};
</script>

<style lang="scss" src="./layout/projects.scss" scoped></style>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');

#intro {
  position: relative;
  z-index: 1;
}

#idea {
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  margin-top: -128px;

  .outer {
    background-color: $background-light;
    border-top: 128px solid $background;
    padding-bottom: 128px;

    @media all and ($mobile) {
      padding-bottom: 96px;
    }

    .content {
      margin-top: -128px;

      .inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media all and ($mobile) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
        }

        .mockup {
          margin-left: -48px;
          margin-right: -48px;

          @media all and ($mobile) {
            margin-left: -24px;
            margin-right: 0;
            width: calc(100% + 2 * 24px);
            display: flex;
            justify-content: center;
            align-items: center;
          }

          img {
            width: 384px;
            height: auto;

            @media all and ($desktop) and (max-width: 700px) {
              width: 256px;
            }

            @media all and ($mobile) {
              width: 150%;
            }
          }
        }

        .text {
          margin-top: 256px;
          margin-left: 96px;
          position: relative;

          @media all and ($mobile) {
            width: 100%;
            margin-top: auto;
            margin-left: 0;
          }

          .background-text {
            top: -144px;
            left: 20%;

            @media all and ($mobile) {
              left: -38px;
              top: -144px;
            }
          }
        }
      }

      .diagrams {
        display: flex;
        justify-content: space-around;
        align-items: center;

        img {
          max-width: 40%;
        }

        @media all and ($mobile) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          img {
            max-width: 100%;
            margin-bottom: 32px;

            &:last-child {
              margin-bottom: 0;
              max-width: 70%;
            }
          }
        }
      }
    }
  }
}

#concept {
  .content {
    .background-text {
      left: 25%;

      @media all and ($mobile) {
        left: -14px;
      }
    }

    .procedure {
      .chart {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 1;

        @media all and (max-width: 750px) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }

        .arrow {
          min-width: 64px;
          height: 5px;
          background-color: $background-light;
          border-right: 5px solid $background;
          position: relative;
          z-index: 0;
          box-sizing: border-box;

          &::after {
            content: '';
            display: block;
            width: 22px;
            height: 20px;
            position: absolute;
            right: -5px;
            top: 50%;
            transform: translateY(-50%);
            border-top: 10px solid $background;
            border-bottom: 10px solid $background;
            border-left: 22px solid $background-light;
            box-sizing: border-box;
          }

          @media all and (max-width: 750px) {
            min-width: auto;
            min-height: 64px;
            height: auto;
            width: 5px;
            border-right: none;
            border-bottom: 5px solid $background;

            &::after {
              width: 20px;
              height: 22px;
              right: auto;
              bottom: -5px;
              top: auto;
              left: 50%;
              transform: translateX(-50%);
              border-bottom: none;
              border-left: 10px solid $background;
              border-right: 10px solid $background;
              border-top: 22px solid $background-light;
            }
          }
        }

        .start {
          padding: 8px 24px;
          background-color: rgba($foreground, 0.12);
          border: 1px solid $foreground;
          border-radius: 9999px;
          box-shadow: map-get($elevation, 1);
          z-index: 1;

          p {
            margin: 0;
            padding: 0;
            font-size: 1rem;
            font-weight: 700;
            color: $foreground;
            white-space: nowrap;
          }
        }

        .step {
          padding: 24px;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          background-color: $background-light;
          border-radius: 12px;
          box-shadow: map-get($elevation, 1);
          z-index: 1;

          @media all and (max-width: 750px) {
            width: 100%;
            box-sizing: border-box;
          }

          .number {
            font-family: 'Montserrat', 'Nunito Sans', sans-serif;
            font-size: 1.25rem;
            font-weight: 900;
            color: $primary;
            margin: 0 24px 0 0;
          }

          .text {
            flex-grow: 1;

            .heading {
              font-size: 1.5rem;
              font-weight: 600;
              color: $foreground;
              margin-bottom: 16px;
            }

            .details {
              font-size: 1rem;
              line-height: 1.125rem;
              font-weight: 400;
              margin: 0;
            }
          }
        }
      }
    }

    .goals {
      .boxes {
        display: flex;
        justify-content: center;
        align-items: stretch;
        flex-wrap: wrap;

        .box {
          width: calc(33.3% - 16px);
          height: auto;
          box-sizing: border-box;
          background-color: $background-light;
          border-radius: 20px;
          box-shadow: map-get($elevation, 1);
          padding: 24px;
          margin: 0 24px 24px 0;
          z-index: 1;

          &:nth-child(3) {
            margin: 0 0 24px 0;
          }

          @media all and ($desktop) and (max-width: 950px) {
            width: calc(50% - 12px);

            &:nth-child(even) {
              margin: 0 0 24px 0;
            }

            &:nth-child(3) {
              margin: 0 24px 24px 0;
            }
          }

          @media all and ($mobile) {
            width: 100%;
            margin: 0 0 24px 0;
          }

          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 0 24px 0;

            .title {
              text-align: left;
              font-size: 1.25rem;
              font-weight: 700;
              line-height: 27px;
              color: $foreground;
              margin: 0 16px 0 0;
            }

            .icon {
              flex-shrink: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 64px;
              min-width: 64px;
              height: 64px;
              background-color: rgba($primary, 0.2);
              border-radius: 50%;

              i {
                font-size: 1.5rem;
                color: $primary;
              }
            }
          }

          .details {
            line-height: 1.2rem;
            white-space: pre-line;
          }
        }
      }

      .conclusion {
        margin: 24px 0 0 0;

        @media all and ($mobile) {
          margin: 0;
        }

        .arrow {
          width: 72px;
          height: 72px;
          border-right: 10px solid $background-light;
          border-bottom: 10px solid $background-light;
          transform: rotate(45deg);
          margin: 16px auto;

          @media all and ($mobile) {
            margin-top: 0;
          }
        }

        p {
          font-size: 1.5rem;
          line-height: 2rem;
          font-weight: 700;
          color: $foreground;
          text-align: center;
          margin: 64px 0 0 0;

          @media all and ($mobile) {
            font-size: 1.25rem;
            line-height: 1.6875rem;
          }

          & ::v-deep span {
            position: relative;

            @media all and ($mobile) {
              background-color: rgba($primary, 0.5);

              &::before {
                visibility: hidden;
              }
            }

            &::before {
              content: '';
              display: block;
              position: absolute;
              top: 50%;
              left: -6px;
              transform: translateY(-50%);
              width: calc(100% + 12px);
              height: 65%;
              background-color: rgba($primary, 0.5);
              z-index: -1;
            }
          }
        }
      }
    }
  }
}

#target {
  background-color: $background-light;

  .content {
    .background-text {
      left: 7%;

      @media all and ($mobile) {
        left: -14px;
      }
    }

    .groups {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media all and ($mobile) {
        display: block;
      }

      .group {
        width: calc(50% - 24px);

        &:last-child {
          margin-bottom: 0;
        }

        @media all and ($desktop) {
          margin-bottom: 0;
        }

        @media all and ($mobile) {
          width: 100%;
        }

        .description {
          & ::v-deep span {
            color: $primary;
          }
        }
      }
    }
  }
}

#design {
  .content {
    .background-text {
      @media all and ($mobile) {
        left: -14px;
      }
    }

    .logo {
      .iteration,
      .final {
        position: relative;

        @media all and ($mobile) {
          margin-bottom: 64px;
        }

        .number {
          position: absolute;
          top: 0;
          left: 0;
          font-family: 'Montserrat', 'Nunito Sans', sans-serif;
          font-size: 6rem;
          font-weight: 900;
          color: rgba($primary, 0.2);
        }

        &:nth-of-type(2) {
          .inner {
            @media all and ($desktop) {
              padding-top: 0;
            }
          }
        }

        .inner {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5% 2%;
          position: relative;
          z-index: 1;

          @media all and ($mobile) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 15% 0;
          }

          img {
            width: 35%;

            @media all and ($mobile) {
              width: 100%;
            }
          }

          .arrow {
            width: 72px;
            height: 72px;
            border-right: 10px solid $background-light;
            border-bottom: 10px solid $background-light;
            transform: rotate(-45deg);

            @media all and ($mobile) {
              transform: rotate(45deg);
              margin-bottom: 48px;
            }
          }
        }
      }

      .final {
        .inner {
          padding: 6%;

          @media all and ($mobile) {
            padding: 18% 0 0 0;
          }

          img {
            min-width: 45%;

            @media all and ($mobile) {
              width: 100%;
            }
          }

          .description {
            color: $foreground;
            padding-top: 15%;
            margin-left: 48px;

            @media all and ($mobile) {
              padding-top: 24px;
              margin-left: 0;
              margin-bottom: 0;
            }

            & ::v-deep span {
              color: $primary;
            }
          }
        }
      }
    }

    .colors {
      .inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media all and ($mobile) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }

        .swatches {
          flex-grow: 1;
          display: grid;
          grid-template-rows: [row-start] 1fr [row-start] 1fr [row-start] 0.6fr;
          grid-template-columns: repeat(6, 0.666666667fr);
          grid-template-areas:
            'first first first second second second'
            'first first first third third third'
            'fourth fourth fifth fifth sixth sixth';
          grid-gap: 24px;
          min-height: 448px;
          width: 70%;

          @media all and ($mobile) {
            grid-gap: 12px;
            min-height: 256px;
            width: 100%;
          }

          & > * {
            &:nth-child(1) {
              grid-area: first;
            }

            &:nth-child(2) {
              grid-area: second;
            }

            &:nth-child(3) {
              grid-area: third;
            }

            &:nth-child(4) {
              grid-area: fourth;
            }

            &:nth-child(5) {
              grid-area: fifth;
            }

            &:nth-child(6) {
              grid-area: sixth;
            }
          }
        }

        .text {
          flex-grow: 1;
          margin-left: 24px;

          @media all and ($mobile) {
            margin-top: 24px;
            margin-left: 0;
          }

          p {
            font-size: 1.25rem;
            font-weight: 700;
            color: $foreground;
            text-align: left;

            @media all and ($mobile) {
              font-size: 1.125rem;
            }
          }
        }
      }
    }

    .typography {
      .inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media all and ($mobile) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }

        .description {
          padding-top: 16px;
          min-width: 50%;
          max-width: 50%;
          white-space: pre-line;

          @media all and ($mobile) {
            padding-top: 0;
            padding-bottom: 24px;
            min-width: 100%;
            max-width: 100%;
          }
        }

        .fonts {
          margin-top: -32px;
          margin-left: 80px;

          @media all and ($mobile) {
            margin-top: 0;
            margin-left: 0;
          }

          & > * {
            margin-bottom: 24px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

#result {
  background-color: $background-light;
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    background-color: $background;
  }

  &::before {
    top: 35.45%;
    height: 15%;

    @media all and ($mobile) {
      top: 32.568%;
    }
  }

  &::after {
    top: 81.4%;
    height: 18.6%;
  }

  .content {
    .background-text {
      left: 30%;

      @media all and ($mobile) {
        left: -14px;
      }
    }

    .intro {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      z-index: 1;

      @media all and ($mobile) {
        flex-direction: column;
        justify-content: flex-start;
      }

      h2.title {
        flex-grow: 1;
        min-width: 164px;
      }

      .description {
        margin-left: 70px;
        color: $foreground;

        @media all and ($mobile) {
          margin-left: 0;
          margin-top: 32px;
        }

        a {
          text-decoration: none;
          outline: none;
          color: $primary;
        }
      }
    }

    .gallery {
      display: grid;
      grid-template-columns: repeat(3, calc(33.33333% - 21.3333333px));
      grid-template-rows: repeat(5, auto);
      grid-template-areas:
        'scHome scHome textHome'
        'scHome scHome scShop'
        'scAbout scAbout scShop'
        'scPackaging1 scPackaging2 scShop'
        'scHome2 scHome2 scHome2';
      gap: 32px;
      width: 100%;
      max-width: 100%;
      position: relative;
      z-index: 1;
      margin-bottom: -160px;

      @media all and ($mobile) {
        grid-template-columns: repeat(2, calc(50% - 8px));
        grid-template-rows: repeat(6, auto);
        grid-template-areas:
          'textHome textHome'
          'scHome scHome'
          'scShop scPackaging1'
          'scShop scPackaging2'
          'scAbout scAbout'
          'scHome2 scHome2';
        gap: 16px;
        margin-bottom: -128px;
      }

      & > picture {
        display: flex;
        justify-content: stretch;
        align-items: center;
        width: 100%;

        img {
          width: 100%;
          height: auto;
        }
      }

      .home {
        margin: 0;
      }

      .scHome,
      .scAbout,
      .scHome2 {
        border-radius: 12px;
        box-shadow: map-get($elevation, 3);
        overflow: hidden;
      }

      .scShop,
      .scPackaging1,
      .scPackaging2 {
        border-radius: 8px;
        box-shadow: map-get($elevation, 3);
        overflow: hidden;

        @media all and ($mobile) {
          align-self: flex-start;
        }
      }

      .scHome {
        grid-area: scHome;
      }

      .home {
        grid-area: textHome;
      }

      .scShop {
        grid-area: scShop;
        margin-bottom: 48px;

        @media all and ($mobile) {
          margin-bottom: 0;
        }
      }

      .scAbout {
        grid-area: scAbout;
      }

      .scPackaging1 {
        grid-area: scPackaging1;

        @media all and ($mobile) {
          align-self: flex-end;
        }
      }

      .scPackaging2 {
        grid-area: scPackaging2;
      }

      .scHome2 {
        grid-area: scHome2;
      }
    }
  }
}
</style>
